import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import _ from 'lodash'
import DefaultPage from "../components/DefaultPage"
import {SEO} from "../components/SEO"

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHome {
        banner {
          ... on StrapiComponentHeaderImageBanner {
            title
            subTitle
            images {
              strapiId
              name
              file {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        sidebar {
          ... on StrapiComponentSidebarAnnouncementSidebar {
            nextOpeningTimeEnabled
            announcementsEnabled
          }
        }
        panels {
          ...ContentPanel
          ...MapPanel
          ...TeamPanel
        }
      }
    }
  `)

  const {
    strapiHome: {
      banner: banners,
      sidebar,
      panels = [],
    } = {}
  } = _.pickBy(data, (v) => v !== null)

  return (
    <DefaultPage
      banners={banners}
      sidebar={sidebar}
      panels={panels}
    />
  )
}

export default Index

export const Head = () => (
  <SEO />
)
